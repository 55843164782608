import { environment } from '@env/environment';
import { UserConsentDto, UserDto } from './user.dto';

export class User {
    id: string;
    fullname: string;
    email: string;
    phone: UserPhone;
    birthdate: string;
    created: string;
    consent: UserConsent;
    constructor(data: UserDto) {
        this.id = data.id;
        this.fullname = data.fullname;
        this.email = data.email;
        this.phone = new UserPhone(data.phoneNumber);
        this.birthdate = data.birthdate?.split('-').join('/') ?? '';
        this.consent = new UserConsent(data.consent);
        this.created = data.createdAt;
    }
}

export class UserPhone {
    prefix: string;
    number: string;
    constructor(phone: string | null) {
        this.prefix = environment.forms.phone.prefixes.default;
        this.number = phone?.split(' ').join('') ?? '';

        const prefixes = environment.forms.phone.prefixes.items.map((item) => item.code);
        const foundPrefix = prefixes.find((prefix) => this.number.startsWith(prefix));

        if (foundPrefix) {
            this.prefix = foundPrefix;
            this.number = phone?.slice(foundPrefix.length).split(' ').join('') ?? '';
        }
    }
}

export class UserConsent {
    marketing: boolean;
    telemarketing: boolean;
    constructor(data: UserConsentDto) {
        this.marketing = data.marketing;
        this.telemarketing = data.telemarketing;
    }
}
