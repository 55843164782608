import { createReducer, on } from '@ngrx/store';
import { CustomerListActions } from '../actions/customer-list.actions';
import { Customer } from '../models';

export interface CustomerListState {
    loading: boolean | null;
    customerDataList: Customer[] | null;
}

export const initialState: CustomerListState = {
    loading: null,
    customerDataList: null,
};

export const customerListReducer = createReducer(
    initialState,
    on(
        CustomerListActions.getAction,
        (state): CustomerListState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        CustomerListActions.getSuccessAction,
        (state, action): CustomerListState => ({
            ...state,
            loading: false,
            customerDataList: action.payload,
        }),
    ),
    on(
        CustomerListActions.getErrorAction,
        (state): CustomerListState => ({
            ...state,
            loading: false,
        }),
    ),
    on(
        CustomerListActions.clearAction,
        (): CustomerListState => ({
            ...initialState,
        }),
    ),
);
