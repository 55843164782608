import { Injectable, inject } from '@angular/core';
import { LoaderFacade } from '@app/store/loader';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { CustomerListActions } from '../actions/customer-list.actions';
import { HttpService } from '../services/http.service';
import { CustomerDetailsActions } from '../actions/customer.actions';

@Injectable()
export class CustomerListEffects {
    private readonly actions = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loaderFacade = inject(LoaderFacade);

    getCustomerDetailsList$ = createEffect(() => {
        return this.actions.pipe(
            ofType(
                CustomerListActions.getAction,
                CustomerDetailsActions.updateSuccessAction,
                CustomerDetailsActions.createSuccessAction,
                CustomerDetailsActions.deleteSuccessAction,
            ),
            mergeMap(() => {
                this.loaderFacade.add('customer-list-get');
                return this.httpService.getCustomers().pipe(
                    map((payload) => CustomerListActions.getSuccessAction({ payload })),
                    catchError(() => of(CustomerListActions.getErrorAction())),
                    finalize(() => this.loaderFacade.remove('customer-list-get')),
                );
            }),
        );
    });
}
