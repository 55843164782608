import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';
import { USER_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: UserState) => {
    return state.loaded;
});

export const selectUser = createSelector(selectState, (state: UserState) => {
    return state.user;
});

export const selectErrors = createSelector(selectState, (state: UserState) => {
    return state.errors;
});

export const selectPasswordChanged = createSelector(selectState, (state: UserState) => {
    return state.passwordChanged;
});

export const selectUpdated = createSelector(selectState, (state: UserState) => {
    return state.updated;
});

export const selectLoading = createSelector(selectState, (state: UserState) => {
    return state.loading;
});
