import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HeremapsStoreModule } from '../heremaps';

import { CUSTOMER_FEATURE_KEY, CUSTOMER_LIST_FEATURE_KEY } from './keys';
import { customerReducer } from './reducers/customer.reducer';
import { CustomerEffects } from './effects/customer.effects';
import { CustomerListEffects } from './effects/customer-list.effects';
import { customerListReducer } from './reducers/customer-list.reducer';

@NgModule({
    imports: [
        CommonModule,
        HeremapsStoreModule,
        StoreModule.forFeature(CUSTOMER_FEATURE_KEY, customerReducer),
        StoreModule.forFeature(CUSTOMER_LIST_FEATURE_KEY, customerListReducer),
        EffectsModule.forFeature([CustomerEffects, CustomerListEffects]),
    ],
})
export class CustomerStoreModule {}
