import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { User, UserDto, UserValueDto, UserValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getUser(): Observable<User> {
        return this.httpClient
            .addServer()
            .skipRefreshToken()
            .addRefreshTokenSilent()
            .get<ApiResponse<UserDto>>(`api/user`)
            .pipe(map((response) => new User(response.data)));
    }

    updateUser(value: UserValue): Observable<unknown> {
        return this.httpClient.addServer().put<ApiResponse<unknown>>(`api/user`, new UserValueDto(value));
    }

    changePassword(newPassword: string, currentPassword: string) {
        const params = {
            currentPassword: currentPassword,
            newPassword: newPassword,
        };

        return this.httpClient
            .addServer()
            .post<ApiResponse<unknown>>('api/user/password-change', params)
            .pipe(
                map((response) => {
                    if (response.success === false) {
                        throw response.data;
                    }
                    return response;
                }),
            );
    }
}
