import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { selectErrors, selectLoaded, selectLoading, selectPasswordChanged, selectUpdated, selectUser } from '../selectors/user.selector';
import { userActions } from '../actions/user.actions';
import { UserValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class UserFacade {
    private store = inject(Store);

    readonly loaded$ = this.store.select(selectLoaded);
    readonly loading$ = this.store.select(selectLoading).pipe(filter((data) => data !== null));
    readonly user$ = this.store.select(selectUser);
    readonly userNonNullalbe$ = this.store.select(selectUser).pipe(filter((user) => user !== null));
    readonly errors$ = this.store.select(selectErrors).pipe(filter((data) => data !== null));
    readonly passwordChanged$ = this.store.select(selectPasswordChanged).pipe(filter((data) => data !== null));
    readonly updated$ = this.store.select(selectUpdated).pipe(filter((data) => data !== null));

    get loaded(): boolean | null {
        let loaded: boolean | null = false;
        this.loaded$
            .pipe(
                take(1),
                tap((value) => (loaded = value)),
            )
            .subscribe();
        return loaded;
    }

    loadUser(): void {
        this.store.dispatch(userActions.get());
    }

    changePassword(newPassword: string, currentPassword: string): void {
        this.store.dispatch(userActions.changePassword({ newPassword, currentPassword }));
    }

    updateUser(value: UserValue): void {
        this.store.dispatch(userActions.update({ payload: value }));
    }

    clear(): void {
        this.store.dispatch(userActions.clear());
    }
}
