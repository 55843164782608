import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AlertsService } from '@app/shared/alerts';
import { ErrorsModel } from '@app/store/account/models';
import { LoaderFacade } from '@app/store/loader';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, tap, finalize } from 'rxjs';
import { userActions } from '../actions/user.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class UserEffects {
    private readonly actions = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loaderFacade = inject(LoaderFacade);
    private readonly alertsService = inject(AlertsService);

    getUser$ = createEffect(() => {
        return this.actions.pipe(
            ofType(userActions.get, userActions.updateSuccess),
            mergeMap(() => {
                this.loaderFacade.add('get-user');
                return this.httpService.getUser().pipe(
                    map((user) => userActions.getSuccess({ user })),
                    catchError((response: HttpErrorResponse) => of(userActions.getError({ code: response.status }))),
                    finalize(() => this.loaderFacade.remove('get-user')),
                );
            }),
        );
    });

    changePassword$ = createEffect(() => {
        return this.actions.pipe(
            ofType(userActions.changePassword),
            mergeMap((action) => {
                this.loaderFacade.add('change-password');
                return this.httpService.changePassword(action.newPassword, action.currentPassword).pipe(
                    map(() => userActions.changePasswordSuccess()),
                    catchError((errors: ErrorsModel) => of(userActions.changePasswordError({ errors }))),
                    finalize(() => this.loaderFacade.remove('change-password')),
                );
            }),
        );
    });

    changePasswordSuccess$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(userActions.changePasswordSuccess),
                tap(() => this.alertsService.show('alerts.password.change.success', 'success', true)),
            );
        },
        {
            dispatch: false,
        },
    );

    updateUser$ = createEffect(() => {
        return this.actions.pipe(
            ofType(userActions.update),
            mergeMap((action) => {
                this.loaderFacade.add('update-user');
                return this.httpService.updateUser(action.payload).pipe(
                    map(() => userActions.updateSuccess()),
                    catchError((errors: ErrorsModel) => of(userActions.updateError({ errors }))),
                    finalize(() => this.loaderFacade.remove('update-user')),
                );
            }),
        );
    });

    updateUserSuccess$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(userActions.updateSuccess),
                tap(() => this.alertsService.show('alerts.mydata.change.success', 'success', true)),
            );
        },
        {
            dispatch: false,
        },
    );

    updateUserResetFlag$ = createEffect(() => {
        return this.actions.pipe(
            ofType(userActions.updateSuccess, userActions.updateError),
            map(() => userActions.updateResetFlag()),
        );
    });
}
