import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { CustomerListActions } from '../actions/customer-list.actions';
import { selectCountDataList, selectCustomer, selectCustomerDataList } from '../selectors/customer-list.selector';

@Injectable({
    providedIn: 'root',
})
export class CustomerListFacade {
    private store = inject(Store);

    readonly list$ = this.store.select(selectCustomerDataList).pipe(filter((data) => data !== null));
    readonly count$ = this.store.select(selectCountDataList).pipe(filter((data) => data !== null));
    readonly customer$ = (id: string) => this.store.select(selectCustomer(id)).pipe(filter((data) => data !== null));

    getCustomers() {
        this.store.dispatch(CustomerListActions.getAction());
    }

    clear(): void {
        this.store.dispatch(CustomerListActions.clearAction());
    }
}
