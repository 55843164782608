import { environment } from '@env/environment';
import { CustomerDto, CustomerAddressDto } from './customer.dto';
import { BillingType } from '../types/billing.type';

export class Customer {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: CustomerPhone;
    companyName: string;
    nip: string;
    address: CustomerAddress;
    type: BillingType;

    constructor(data: CustomerDto) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.phone = new CustomerPhone(data.phone);
        this.companyName = data.companyName ? data.companyName : '';
        this.nip = data.nip ? data.nip : '';
        this.address = new CustomerAddress(data.address);
        this.type = data.type;
    }
}

export class CustomerAddress {
    country: string;
    city: string;
    voivodeship: string;
    street: string;
    houseNumber: string;
    flatNumber: string;
    postalCode: string;
    constructor(data: CustomerAddressDto) {
        this.country = data.country ?? null;
        this.city = data.city;
        this.voivodeship = data.voivodeship;
        this.street = data.street;
        this.houseNumber = data.houseNumber;
        this.flatNumber = data.flatNumber;
        this.postalCode = data.postalCode;
    }
}

export class CustomerPhone {
    prefix: string;
    number: string;
    constructor(phone: string | null) {
        this.prefix = environment.forms.phone.prefixes.default;
        this.number = phone?.split(' ').join('') ?? '';

        const prefixes = environment.forms.phone.prefixes.items.map((item) => item.code);
        const foundPrefix = prefixes.find((prefix) => this.number.startsWith(prefix));

        if (foundPrefix) {
            this.prefix = foundPrefix;
            this.number = phone?.slice(foundPrefix.length).split(' ').join('') ?? '';
        }
    }
}
