import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Customer } from '../models';

export const CustomerListActions = createActionGroup({
    source: 'Customer List',
    events: {
        'Get Action': emptyProps(),
        'Get Success Action': props<{ payload: Customer[] }>(),
        'Get Error Action': emptyProps(),
        'Clear Action': emptyProps(),
    },
});
