import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { CustomerValue } from '../models';
import { selectCreated, selectDeleted, selectLoading, selectUpdated } from '../selectors/customer.selector';
import { CustomerDetailsActions } from '../actions/customer.actions';

@Injectable({
    providedIn: 'root',
})
export class CustomerFacade {
    private store = inject(Store);

    readonly created$ = this.store.select(selectCreated).pipe(filter((data) => data !== null));
    readonly updated$ = this.store.select(selectUpdated).pipe(filter((data) => data !== null));
    readonly deleted$ = this.store.select(selectDeleted).pipe(filter((data) => data !== null));
    readonly loading$ = this.store.select(selectLoading).pipe(filter((data) => data !== null));

    create(value: CustomerValue) {
        this.store.dispatch(CustomerDetailsActions.createAction({ value }));
    }

    update(id: string, value: CustomerValue) {
        this.store.dispatch(CustomerDetailsActions.updateAction({ id, value }));
    }

    delete(id: string) {
        this.store.dispatch(CustomerDetailsActions.deleteAction({ id }));
    }

    clear() {
        this.store.dispatch(CustomerDetailsActions.clearAction());
    }
}
