import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CUSTOMER_LIST_FEATURE_KEY } from '../keys';
import { CustomerListState } from '../reducers/customer-list.reducer';

export const selectCustomerListState = createFeatureSelector<CustomerListState>(CUSTOMER_LIST_FEATURE_KEY);

export const selectCustomerDataList = createSelector(selectCustomerListState, (state: CustomerListState) => {
    return state.customerDataList;
});

export const selectCustomer = (id: string) =>
    createSelector(selectCustomerListState, (state: CustomerListState) => state.customerDataList?.find((customer) => customer.id === id) ?? null);

export const selectCountDataList = createSelector(selectCustomerListState, (state: CustomerListState) => {
    return state.customerDataList ? state.customerDataList.length : 0;
});
