import { ErrorsModel } from '@app/store/account/models';
import { props } from '@ngrx/store';
import { User, UserValue } from '../models';

import { createActionGroup, emptyProps } from '@ngrx/store';

export const userActions = createActionGroup({
    source: 'User',
    events: {
        Get: emptyProps(),
        'Get Success': props<{ user: User }>(),
        'Get Error': props<{ code: number }>(),
        'Change Password': props<{ newPassword: string; currentPassword: string }>(),
        'Change Password Success': emptyProps(),
        'Change Password Error': props<{ errors: ErrorsModel }>(),
        Update: props<{ payload: UserValue }>(),
        'Update Success': emptyProps(),
        'Update Error': props<{ errors: ErrorsModel }>(),
        'Update Reset Flag': emptyProps(),
        Clear: emptyProps(),
    },
});
