import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { Customer, CustomerDto, CustomerValue, CustomerValueDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getCustomers(): Observable<Customer[]> {
        return this.httpClient
            .addServer()
            .get<ApiResponse<CustomerDto[]>>('api/user/customer_details')
            .pipe(map((response) => response.data.map((item) => new Customer(item))));
    }

    add(value: CustomerValue) {
        const params = new CustomerValueDto(value);

        return this.httpClient.addServer().post<ApiResponse<CustomerDto[]>>('api/user/customer_details', params);
    }

    update(id: string, value: CustomerValue) {
        const params = new CustomerValueDto(value);

        return this.httpClient.addServer().put<ApiResponse<unknown>>(`api/user/customer_details/${id}`, params);
    }

    delete(id: string): Observable<unknown> {
        return this.httpClient.addServer().delete<unknown>(`api/user/customer_details/${id}`);
    }
}
