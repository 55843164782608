import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { USER_FEATURE_KEY } from './keys';
import { userReducer } from './reducers/user.reducer';
import { UserEffects } from './effects/user.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(USER_FEATURE_KEY, userReducer), EffectsModule.forFeature([UserEffects])],
})
export class UserStoreModule {}
