import { createReducer, on } from '@ngrx/store';
import { CustomerDetailsActions } from '../actions/customer.actions';

export interface CustomerState {
    loading: boolean | null;
    created: boolean | null;
    updated: boolean | null;
    deleted: boolean | null;
}

export const initialState: CustomerState = {
    loading: null,
    created: null,
    deleted: null,
    updated: null,
};

export const customerReducer = createReducer(
    initialState,
    on(
        CustomerDetailsActions.clearAction,
        (state): CustomerState => ({
            ...state,
            ...initialState,
        }),
    ),
    on(
        CustomerDetailsActions.createAction,
        (state): CustomerState => ({
            ...state,
            loading: true,
            created: null,
        }),
    ),
    on(
        CustomerDetailsActions.createSuccessAction,
        (state): CustomerState => ({
            ...state,
            loading: false,
            created: true,
        }),
    ),
    on(
        CustomerDetailsActions.createErrorAction,
        (state): CustomerState => ({
            ...state,
            loading: false,
            created: false,
        }),
    ),
    on(
        CustomerDetailsActions.createReset,
        (state): CustomerState => ({
            ...state,
            loading: null,
            created: null,
        }),
    ),
    on(
        CustomerDetailsActions.updateAction,
        (state): CustomerState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        CustomerDetailsActions.updateSuccessAction,
        (state): CustomerState => ({
            ...state,
            loading: false,
            updated: true,
        }),
    ),
    on(
        CustomerDetailsActions.updateErrorAction,
        (state): CustomerState => ({
            ...state,
            loading: false,
        }),
    ),
    on(
        CustomerDetailsActions.updateReset,
        (state): CustomerState => ({
            ...state,
            loading: null,
            updated: null,
        }),
    ),
    on(
        CustomerDetailsActions.deleteAction,
        (state): CustomerState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        CustomerDetailsActions.deleteSuccessAction,
        (state): CustomerState => ({
            ...state,
            loading: false,
            deleted: true,
        }),
    ),
    on(
        CustomerDetailsActions.deleteErrorAction,
        (state): CustomerState => ({
            ...state,
            loading: false,
        }),
    ),
    on(
        CustomerDetailsActions.deleteReset,
        (state): CustomerState => ({
            ...state,
            loading: null,
            deleted: null,
        }),
    ),
);
