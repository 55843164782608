import { BillingType } from '../types/billing.type';
import { CustomerAddressValue, CustomerValue } from './customer.value';

export class CustomerValueDto {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string | null = null;
    nip: string | null = null;
    type: BillingType;
    address: CustomerAddressValueDto;

    constructor(value: CustomerValue) {
        this.firstName = value.firstName.trim();
        this.lastName = value.lastName.trim();
        this.email = value.email.trim();
        this.phone = `${value.phone.prefix}${value.phone.number}`.trim();
        this.type = value.billing.type;

        if (value.billing.type === 'company') {
            this.companyName = value.billing.companyName;
            this.nip = value.billing.nip;
        }

        this.address = new CustomerAddressValueDto(value.billing.address);
    }
}

export class CustomerAddressValueDto {
    country: string | null;
    city: string;
    voivodeship = '';
    street: string;
    houseNumber = '';
    flatNumber = '';
    postalCode: string;

    constructor(value: CustomerAddressValue) {
        this.country = value.country.trim() || null;
        this.city = value.city.trim();
        this.street = value.street.trim();
        this.postalCode = value.postalCode.trim();
    }
}
