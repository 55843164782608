import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CustomerValue } from '../models';

export const CustomerDetailsActions = createActionGroup({
    source: 'Customer',
    events: {
        'Create Action': props<{ value: CustomerValue }>(),
        'Create Success Action': emptyProps(),
        'Create Error Action': emptyProps(),
        'Create Reset': emptyProps(),

        'Update Action': props<{ id: string; value: CustomerValue }>(),
        'Update Success Action': emptyProps(),
        'Update Error Action': emptyProps(),
        'Update Reset': emptyProps(),

        'Delete Action': props<{ id: string }>(),
        'Delete Success Action': emptyProps(),
        'Delete Error Action': emptyProps(),
        'Delete Reset': emptyProps(),

        'Clear Action': emptyProps(),
    },
});
