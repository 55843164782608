import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CUSTOMER_FEATURE_KEY } from '../keys';
import { CustomerState } from '../reducers/customer.reducer';

export const selectCustomerState = createFeatureSelector<CustomerState>(CUSTOMER_FEATURE_KEY);

export const selectDeleted = createSelector(selectCustomerState, (state: CustomerState) => state.deleted);
export const selectCreated = createSelector(selectCustomerState, (state: CustomerState) => state.created);
export const selectUpdated = createSelector(selectCustomerState, (state: CustomerState) => state.updated);
export const selectLoading = createSelector(selectCustomerState, (state: CustomerState) => state.loading);
