import { Injectable, inject } from '@angular/core';
import { LoaderFacade } from '@app/store/loader';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, of } from 'rxjs';
import { CustomerDetailsActions } from '../actions/customer.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class CustomerEffects {
    private readonly actions = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loaderFacade = inject(LoaderFacade);

    createCustomerDetail$ = createEffect(() => {
        return this.actions.pipe(
            ofType(CustomerDetailsActions.createAction),
            mergeMap((action) => {
                this.loaderFacade.add('customer-create');
                return this.httpService.add(action.value).pipe(
                    map(() => CustomerDetailsActions.createSuccessAction()),
                    catchError(() => of(CustomerDetailsActions.createErrorAction())),
                    finalize(() => this.loaderFacade.remove('customer-create')),
                );
            }),
        );
    });

    createdReset$ = createEffect(() => {
        return this.actions.pipe(
            ofType(CustomerDetailsActions.createSuccessAction, CustomerDetailsActions.createErrorAction),
            map(() => CustomerDetailsActions.createReset()),
        );
    });

    updateCustomerDetail$ = createEffect(() => {
        return this.actions.pipe(
            ofType(CustomerDetailsActions.updateAction),
            mergeMap((action) => {
                this.loaderFacade.add('customer-update');
                return this.httpService.update(action.id, action.value).pipe(
                    map(() => CustomerDetailsActions.updateSuccessAction()),
                    catchError(() => of(CustomerDetailsActions.updateErrorAction())),
                    finalize(() => this.loaderFacade.remove('customer-update')),
                );
            }),
        );
    });

    updateReset$ = createEffect(() => {
        return this.actions.pipe(
            ofType(CustomerDetailsActions.updateSuccessAction, CustomerDetailsActions.updateErrorAction),
            map(() => CustomerDetailsActions.updateReset()),
        );
    });

    deleteCustomerDetail$ = createEffect(() => {
        return this.actions.pipe(
            ofType(CustomerDetailsActions.deleteAction),
            mergeMap((action) => {
                this.loaderFacade.add('customer-delete');
                return this.httpService.delete(action.id).pipe(
                    map(() => CustomerDetailsActions.deleteSuccessAction()),
                    catchError(() => of(CustomerDetailsActions.deleteErrorAction())),
                    finalize(() => this.loaderFacade.remove('customer-delete')),
                );
            }),
        );
    });

    deleteReset$ = createEffect(() => {
        return this.actions.pipe(
            ofType(CustomerDetailsActions.deleteSuccessAction, CustomerDetailsActions.deleteErrorAction),
            map(() => CustomerDetailsActions.deleteReset()),
        );
    });
}
