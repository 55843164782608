import { ErrorsModel } from '@app/store/account/models';
import { createReducer, on } from '@ngrx/store';
import { userActions } from '../actions/user.actions';
import { User } from '../models';

export interface UserState {
    loaded: boolean | null;
    loading: boolean | null;
    updated: boolean | null;
    user: User | null;
    errors: ErrorsModel | null;
    passwordChanged: boolean | null;
}

export const initialState: UserState = {
    loaded: null,
    user: null,
    errors: null,
    passwordChanged: null,
    updated: null,
    loading: null,
};

export const userReducer = createReducer(
    initialState,
    on(
        userActions.get,
        (state): UserState => ({
            ...state,
            updated: null,
            loaded: null,
        }),
    ),
    on(
        userActions.getSuccess,
        (state, payload): UserState => ({
            ...state,
            loaded: true,
            user: payload.user,
        }),
    ),
    on(
        userActions.getError,
        (state, payload): UserState => ({
            ...state,
            ...(payload.code === 401 ? { loaded: false } : initialState),
        }),
    ),
    on(
        userActions.update,
        (state): UserState => ({
            ...state,
            loaded: true,
            loading: true,
        }),
    ),
    on(
        userActions.updateSuccess,
        (state): UserState => ({
            ...state,
            loaded: false,
            updated: true,
            loading: false,
        }),
    ),
    on(
        userActions.updateError,
        (state, action): UserState => ({
            ...state,
            loaded: false,
            errors: action.errors,
            loading: false,
        }),
    ),
    on(
        userActions.updateResetFlag,
        (state): UserState => ({
            ...state,
            updated: null,
        }),
    ),
    on(
        userActions.changePassword,
        (state): UserState => ({
            ...state,
            loaded: true,
        }),
    ),
    on(
        userActions.changePasswordSuccess,
        (state): UserState => ({
            ...state,
            loaded: false,
            passwordChanged: true,
        }),
    ),
    on(
        userActions.changePasswordError,
        (state, action): UserState => ({
            ...state,
            loaded: false,
            errors: action.errors,
        }),
    ),
    on(
        userActions.clear,
        (): UserState => ({
            ...initialState,
            loaded: false,
        }),
    ),
);
